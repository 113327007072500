import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/nav',
    name: 'nav',
    component: () => import('../views/Nav.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/Comment/Center.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Comment/Search.vue')
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('../views/Comment/Addr.vue')
  },
  // 设置页
  {
    path: '/set',
    name: 'set',
    component: () => import('../views/Comment/Set.vue')
  },
  // 卡券页
  {
    path: '/card',
    name: 'card',
    component: () => import('../views/Card/Card.vue'),
    meta: { requireAuth: true },
  },
  // 卡号查余额页
  {
    path: '/remain',
    name: 'remain',
    component: () => import('../views/Card/Remain.vue')
  },
  // 卡号绑定页
  {
    path: '/bind',
    name: 'bind',
    component: () => import('../views/Card/Bind.vue')
  },
  // 卡券详情页
  {
    path: '/carddetail',
    name: 'carddetail',
    component: () => import('../views/Card/CardDetail.vue')
  },
  // 卡券消费记录
  {
    path: '/cardConsumptionRecord',
    name: 'cardConsumptionRecord',
    component: () => import('../views/Card/CardConsumptionRecord.vue')
  },
  // 卡号绑定流程
  {
    path: '/cardbind',
    name: 'cardbind',
    component: () => import('../views/Card/CardBind.vue')
  },
  //卡券商品兑换页
  {
    path: '/productexchange',
    name: 'productexchange',
    component: () => import('../views/Card/ProductExchange.vue')
  },
  // 次卡商品兑换页
  {
    path: '/timeproductexchange',
    name: 'timeproductexchange',
    component: () => import('../views/Card/TimeProductExchange.vue')
  },
  // 蛋糕
  {
    path: '/cakes',
    name: 'cakes',
    component: () => import('../views/Cakes/Cakes.vue'),
    meta: { requireAuth: true },
  },
  // 蛋糕分类页
  {
    path: '/cakecategory',
    name: 'cakecategory',
    component: () => import('../views/Cakes/CakeCategory.vue')
  },
  // 维尔纳斯店铺列表页
  {
    path: '/wensshop',
    name: 'wensshop',
    component: () => import('../views/Cakes/WensShop.vue')
  },
  // 维尔纳斯选地址
  {
    path: '/wensaddress',
    name: 'wensaddress',
    component: () => import('../views/Cakes/WensAddress.vue')
  },
  // 维尔纳斯店铺下的蛋糕列表页
  {
    path: '/wenscakeslist',
    name: 'wenscakeslist',
    component: () => import('../views/Cakes/WensCakesList.vue')
  },
  // 维尔纳斯蛋糕详情页
  {
    path: '/wenscakesdetail',
    name: 'wenscakesdetail',
    component: () => import('../views/Goods/WensCakesDetail.vue')
  },
  // 一级类目页
  {
    path: '/category',
    name: 'category',
    component: () => import('../views/Category/Category.vue'),
    meta: { requireAuth: true },
  },
  // 二级类目页
  {
    path: '/categorysec',
    name: 'categorysec',
    component: () => import('../views/Category/CategorySec.vue')
  },
  // 严选
  {
    path: '/bestchoice',
    name: 'bestchoice',
    component: () => import('../views/BestChoice/BestChoice.vue'),
    meta: { requireAuth: true },
  },
  // 图书
  {
    path: '/books',
    name: 'books',
    component: () => import('../views/Books/Books.vue'),
    meta: { requireAuth: true },
  },
  // 乡村振兴
  {
    path: '/ruralrevitalization',
    name: 'ruralrevitalization',
    component: () => import('../views/RuralRevitalization/RuralRevitalization.vue'),
    meta: { requireAuth: true },
  },
  // 电影
  {
    path: '/movies',
    name: 'movies',
    component: () => import('../views/Movies/Movies.vue'),
    meta: { requireAuth: true },
  },
  // 选影院页
  {
    path: '/selectcinema',
    name: 'selectCinema',
    component: () => import('../views/Movies/SelectCinema.vue')

  },
  // 选场次页
  {
    path: '/session',
    name: 'session',
    component: () => import('../views/Movies/Session.vue')
  },
  // 选座页
  {
    path: '/seat',
    name: 'seat',
    component: () => import('../views/Movies/Seat.vue')
  },
  // 视听页
  {
    path: '/cudition',
    name: 'cudition',
    component: () => import('../views/Cudition/Cudition.vue')
  },
  // 兑换券页
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('../views/Movies/Exchange.vue')
  },
  // 演出
  {
    path: '/performance',
    name: 'performance',
    component: () => import('../views/Performance/Performance.vue'),
    meta: { requireAuth: true },
  },
  // 门票
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('../views/Tickets/Tickets.vue'),
    meta: { requireAuth: true },
  },
  // 门票热销榜
  {
    path: '/ticketshot',
    name: 'ticketshot',
    component: () => import('../views/Tickets/TicketsHot.vue'),
  },
  // 门票分类列表页
  {
    path: '/ticketscategory',
    name: 'ticketscategory',
    component: () => import('../views/Tickets/TicketsCategory.vue'),
  },
  // 门票详情
  {
    path: '/ticketsdetail',
    name: 'ticketsdetail',
    component: () => import('../views/Tickets/TicketsDetail.vue'),
  },
  // 门票订单
  {
    path: '/ticketsOrder',
    name: 'ticketsOrder',
    component: () => import('../views/Tickets/TicketsOrder.vue'),
    meta: { requireAuth: true },
  },
  // 门票订单详情
  {
    path: '/ticketsOrderDetail',
    name: 'ticketsOrderDetail',
    component: () => import('../views/Tickets/TicketsOrderDetail.vue'),
    meta: { requireAuth: true },
  },
  // 购物车页面
  {
    path: '/shopcart',
    name: 'shopcart',
    component: () => import('../views/Shop/ShopCart.vue'),
    meta: { requireAuth: true },
  },
  // 购买页1
  {
    path: '/shoporderdetail',
    name: 'shoporderdetail',
    component: () => import('../views/Shop/ShopOrderDetail.vue')
  },
  // 购买页2
  {
    path: '/shoporder',
    name: 'shoporder',
    component: () => import('../views/Shop/ShopOrder.vue')
  },
  // 购买页(无需登录的购买页，无微信补差功能)
  {
    path: '/shopordernologin',
    name: 'shopordernologin',
    component: () => import('../views/Shop/ShopOrderNoLogin.vue')
  },
  // 商品分类
  {
    path: '/goodscategory',
    name: 'goodscategory',
    component: () => import('../views/Goods/GoodsCategory.vue')
  },
  // 商品导航页
  {
    path: '/goodsclassify',
    name: 'goodsclassify',
    component: () => import('../views/Goods/GoodsClassify.vue')
  },
  // 商品详情页
  {
    path: '/goodsdetail',
    name: 'goodsdetail',
    component: () => import('../views/Goods/GoodsDetail.vue')
  },
  // 演出列表
  {
    path: '/performanceList',
    name: 'performanceList',
    component: () => import('../views/Performance/PerformanceList.vue')
  },
  // 演出详情页
  {
    path: '/performanceDetail',
    name: 'performanceDetail',
    component: () => import('../views/Goods/PerformanceDetail.vue')
  },
  // 商品订单页
  {
    path: '/mallorder',
    name: 'mallorder',
    component: () => import('../views/Comment/MallOrder.vue')
  },
  // 退款商品页
  {
    path: '/refundorder',
    name: 'refundorder',
    component: () => import('../views/Comment/RefundOrder.vue')
  },
  // 物流详情页
  {
    path: '/mallexp',
    name: 'mallexp',
    component: () => import('../views/Comment/MallExp.vue')
  },
  // 专区页面
  {
    path: '/activity',
    name: 'activity',
    component: () => import('../views/Activity/Activity.vue')
  },
  // 女神专区页面
  {
    path: '/goddess',
    name: 'goddess',
    component: () => import('../views/Activity/Goddess.vue')
  },
  // 品上海页面
  {
    path: '/pinshanghai',
    name: 'pinshanghai',
    component: () => import('../views/Activity/Pinshanghai.vue')
  },
  // 生活服务
  {
    path: '/lifeservice',
    name: 'lifeservice',
    component: () => import('../views/Activity/LifeService.vue')
  },
  // 鲜果直供
  {
    path: '/freshfruit',
    name: 'freshfruit',
    component: () => import('../views/Activity/FreshFruit.vue')
  },
  // VIP权益
  {
    path: '/vipprivilege',
    name: 'vipprivilege',
    component: () => import('../views/Activity/VipPrivilege.vue')
  },
  // 2023中秋专区
  {
    path: '/midautumn',
    name: 'midautumn',
    component: () => import('../views/Activity/MidAutumn.vue')
  },
  // 仲夏特供
  {
    path: '/zxspecial',
    name: 'zxspecial',
    component: () => import('../views/Zx/ZxSpecial.vue')
  },
  // 仲夏分类页
  {
    path: '/zxcategory',
    name: 'zxcategory',
    component: () => import('../views/Zx/ZxCategory.vue')
  },
  // 电影详情
  {
    path: '/moviesdetail',
    name: 'moviesdetail',
    component: () => import('../views/Movies/MoviesDetail.vue')
  },
  // 电影支付页
  {
    path: '/moviePay',
    name: 'moviePay',
    component: () => import('../views/Movies/MoviePay.vue')
  },
  // 电影查询
  {
    path: '/movieSearch',
    name: 'movieSearch',
    component: () => import('../views/Movies/MovieSearch.vue')
  },
  // 影院页
  {
    path: '/cinema',
    name: 'cinema',
    component: () => import('../views/Movies/Cinema.vue')
  },
  // 电影订单
  {
    path: '/movieorder',
    name: 'movieorder',
    component: () => import('../views/Movies/MovieOrder.vue')
  },
  // 电影订单详情
  {
    path: '/movieOrderDetail',
    name: 'movieOrderDetail',
    component: () => import('../views/Movies/MovieOrderDetail.vue')
  },
  // 选择卡券页面
  {
    path: '/selectCard',
    name: 'selectCard',
    component: () => import('../views/Card/SelectCard.vue')
  },
  // 卡券页面
  {
    path: '/card',
    name: 'card',
    component: () => import('../views/Card/Card.vue')
  },
  // vip专区
  {
    path: '/newhome',
    name: 'newhome',
    component: () => import('../views/Shop/NewHome.vue')
  },
  // 京东
  {
    path: '/jdmoudle',
    name: 'jdmoudle',
    component: () => import('../views/JD/JDModule.vue'),
    meta: { requireAuth: true },
  },
  //门店品牌
  {
    path: '/offlinepay',
    name: 'offlinepay',
    component: () => import('../views/Offline/OfflinePay.vue')
  },
  //提货页面
  {
    path: '/firstpage',
    name: 'firstpage',
    component: () => import('../views/Offline/FirstPage.vue')
  },
  //卡券密码提货
  {
    path: '/inputcard',
    name: 'inputcard',
    component: () => import('../views/Offline/InputCard.vue')
  },
  //扫码提货
  {
    path: '/scancode',
    name: 'scancode',
    component: () => import('../views/Offline/ScanCode.vue')
  },
  //微信支付
  {
    path: '/wechatpay',
    name: 'wechatpay',
    component: () => import('../views/Offline/WeChatPay.vue')
  },
  //提货成功页面
  {
    path: '/paysuccessinfo',
    name: 'paysuccessinfo',
    component: () => import('../views/Offline/PaySuccessInfo.vue')
  },
  //地图
  {
    path: '/maptest',
    name: 'maptest',
    component: () => import('../views/Offline/MapTest.vue')
  },
  //测试页面
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue')
  },
  //卡券视听页面
  {
    path: '/cardvoucher',
    name: 'cardvoucher',
    component: () => import('../views/AudioVisual/CardVoucher.vue')
  },
  // 重庆专区
  {
    path: '/cqspecial',
    name: 'cqspecial',
    component: () => import('../views/CqSpecial/CqSpecial.vue'),
    meta: { requireAuth: true },
  },
  // 蛋糕兑换
  {
    path: '/cakesexchange',
    name: 'cakesexchange',
    component: () => import('../views/Cakes/CakesExchange.vue')
  },
  // 蛋糕兑换订单列表页
  {
    path: '/changepage',
    name: 'changepage',
    component: () => import('../views/Cakes/ChangePage.vue')
  },
  // 蛋糕兑换订单页
  {
    path: '/cakesexorder',
    name: 'cakesexorder',
    component: () => import('../views/Cakes/CakesExOrder.vue')
  },
  // 绑卡手签
  {
    path: '/bindcanvas',
    name: 'bindcanvas',
    component: () => import('../views/BindCanvas/BindCanvas.vue')
  },
  // 提货专区
  {
    path: '/precinct',
    name: 'precinct',
    component: () => import('../views/Precinct/Precinct.vue'),
    meta: { requireAuth: true },
  },
  // 使用说明页
  {
    path: '/explain',
    name: 'explain',
    component: () => import('../views/Precinct/Explain.vue')
  },
  // 选购页
  {
    path: '/choosebuy',
    name: 'choosebuy',
    component: () => import('../views/Precinct/ChooseBuy.vue')
  },
  // 提货商品分类列表页
  {
    path: '/catelist',
    name: 'catelist',
    component: () => import('../views/Precinct/CateList.vue')
  },
  // 分类页
  // 二级分类
  {
    path: '/categorysecond',
    name: 'categorysecond',
    component: () => import('../views/Category/CategorySecond.vue')
  },
  // 三级分类
  {
    path: '/categorythird',
    name: 'categorythird',
    component: () => import('../views/Category/CategoryThird.vue')
  },
  // 商品页
  {
    path: '/categoryproducts',
    name: 'categoryproducts',
    component: () => import('../views/Category/CategoryProducts.vue')
  },
  // 测试滑块
  {
    path: '/tabbar',
    name: 'tabbar',
    component: () => import('../views/tabBar.vue')
  },
  // 四级分类
  {
    path: '/map',
    name: 'map',
    component: () => import('../components/Map.vue')
  },
  // 首页
  {
    path: '/homepage',
    name: 'homepage',
    component: () => import('../views/Home/HomePage.vue'),
    meta: { requireAuth: true },
  },
  // 首页样式1
  {
    path: '/homepage1',
    name: 'homepage1',
    component: () => import('../views/Home/HomePage1.vue'),
    meta: { requireAuth: true },
  },
  // 首页样式2
  {
    path: '/homepage2',
    name: 'homepage2',
    component: () => import('../views/Home/HomePage2.vue'),
    meta: { requireAuth: true },
  },
  // 限时特惠页面
  {
    path: '/limittimeact',
    name: 'limittimeact',
    component: () => import('../views/ProductActivity/LimitTimeAct.vue')
  },
  // 限时特惠页面
  {
    path: '/pickup',
    name: 'pickup',
    component: () => import('../views/PickUp/PickUp.vue'),
    meta: { requireAuth: true },
  },
  //常规提货券单品详情页
  {
    path: '/singlePickInfo',
    name: 'singlePickInfo',
    component: () => import('../views/PickUp/singlePickInfo.vue'),
    meta: { requireAuth: true },
  },
  // 常规提货券组合商品详情页
  {
    path: '/groupPickInfo',
    name: 'groupPickInfo',
    component: () => import('../views/PickUp/groupPickInfo.vue'),
    meta: { requireAuth: true },
  },
  // 停机维护页面
  {
    path: '/shutdownNotice',
    name: 'shutdownNotice',
    component: () => import('../views/Notice/ShutdownNotice.vue')
  },
  // 公司介绍
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import('../views/Notice/Introduce.vue')
  },
  // 网易严选首页
  {
    path: '/netEase',
    name: 'netease',
    component: () => import('../views/NetEase/NetEase.vue')
  },
  // 网页严选分类页
  {
    path: '/netEaseCategory',
    name: 'netEaseCategory',
    component: () => import('../views/NetEase/NetEaseCategory.vue')
  },
  // 网页严选商品详情
  {
    path: '/netEaseGoods',
    name: 'netEaseGoods',
    component: () => import('../views/NetEase/NetEaseGoods.vue')
  },
  // 网页严选订单详情
  {
    path: '/netEaseOrder',
    name: 'netEaseOrder',
    component: () => import('../views/NetEase/NetEaseOrder.vue')
  },
  // 京东卡绑卡
  {
    path: '/jdcardbind',
    name: 'jdcardbind',
    component: () => import('../views/JDcard/JDcardBind.vue')
  },
  // 输入卡号卡密页
  {
    path: '/inputbind',
    name: 'inputbind',
    component: () => import('../views/JDcard/inputBind.vue')
  },
  // 卡号卡密进入后商品列表
  {
    path: '/productslist',
    name: 'productslist',
    component: () => import('../views/JDcard/productsList.vue')
  },
  // 普通vip页
  {
    path: '/vipdirchange',
    name: 'vipdirchange',
    component: () => import('../views/Vip/VipDirChange.vue')
  },
  // vip兑换
  {
    path: '/exchangevip',
    name: 'exchangevip',
    component: () => import('../views/Exchange/ExchangeVip.vue')
  },
  // vip乡村振兴兑换
  {
    path: '/ruralrevitalizationvip',
    name: 'ruralrevitalizationvip',
    component: () => import('../views/Exchange/RuralrevitalizationVip.vue')
  },
  // 美食汇主页
  {
    path: '/food',
    name: 'food',
    component: () => import('../views/Food/Food.vue')
  },
  // 惠亿线下门店登录
  {
    path: '/hyofflinelogin',
    name: 'hyofflinelogin',
    component: () => import('../views/HyOffline/HyOfflineLogin.vue')
  },
  // 惠亿线下门店主页
  {
    path: '/hyofflinehome',
    name: 'hyofflinehome',
    component: () => import('../views/HyOffline/Home.vue')
  },
  // 惠亿线下门店订单页
  {
    path: '/hyofflineorderlist',
    name: 'hyofflineorderlist',
    component: () => import('../views/HyOffline/OrderList.vue')
  },
  // 惠亿线下门店核销卡页
  {
    path: '/check',
    name: 'check',
    component: () => import('../views/HyOffline/Check.vue')
  },
  // 文旅跳转首页
  {
    path: '/outTravelIndex',
    name: 'outTravelIndex',
    component: () => import('../views/outIndex/outTravelIndex.vue')
  },
  // 文旅分类页
  {
    path: '/outTravelGoods',
    name: 'outTravelGoods',
    component: () => import('../views/outIndex/traveGoodsClassify.vue')
  },
  // 文旅分类详情
  {
    path: '/traveGoodsInfo',
    name: 'traveGoodsInfo',
    component: () => import('../views/outIndex/traveGoodsInfo.vue')
  },
  // 定位
  {
    path: '/positioning',
    name: 'positioning',
    component: () => import('../views/Comment/positioning.vue')
  },
  // 城市选择
  {
    path: '/citySelect',
    name: 'citySelect',
    component: () => import('../views/Comment/citySelect.vue')
  },
  // 深远 - 登录
  {
    path: '/syLogin',
    name: 'syLogin',
    component: () => import('../views/syExchange/login.vue')
  },
  // 深远 - 商品列表
  {
    path: '/syGoodsList',
    name: 'syGoodsList',
    component: () => import('../views/syExchange/goodsList.vue')
  },
  // 深远 - 商品详情
  {
    path: '/syGoodsInfo',
    name: 'syGoodsInfo',
    component: () => import('../views/syExchange/goodsInfo.vue')
  },
  // 深远 - 支付成功
  {
    path: '/syPaySuccess',
    name: 'syPaySuccess',
    component: () => import('../views/syExchange/paySuccess.vue')
  },
  // 常规提货券商品展示类目
  {
    path: '/cgShopCategory',
    name: 'cgShopCategory',
    component: () => import('../views/cgShopShow/cgShopCategory.vue')
  },
  // 常规提货券商品展示列表
  {
    path: '/cgShopList',
    name: 'cgShopList',
    component: () => import('../views/cgShopShow/cgShopList.vue')
  },
  // 常规提货券商品详情
  {
    path: '/cgShopInfo',
    name: 'cgShopInfo',
    component: () => import('../views/cgShopShow/cgShopInfo.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
